import logo from "./logo.svg";
import "./App.css";
import { Button, CircularProgress, Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";

function App() {
  const [meetingId, setMeetingId] = useState();
  const [roomEmail, setRoomEmail] = useState();
  const [cancelling, setCancelling] = useState(false);
  const [done, setDone] = useState(false);

  const handleCancelClick = async () => {
    setCancelling(true);

    const url = process.env.REACT_APP_API_URL;
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({
        action: "digestapprove",
        tenantId: process.env.REACT_APP_TENANTID,
        meetingId: meetingId,
        roomEmail: roomEmail,
      }),
    };
    fetch(url, options).then((response) => {
      console.log(response.status);
    });

    setMeetingId();
    setCancelling(false);
    setDone(true);
  };

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    if (params.has("bookingId") && params.has("roomEmail")) {
      setMeetingId(params.get("bookingId"));
      setRoomEmail(params.get("roomEmail"));
    }
  }, []);

  return (
    <div className="box">
      {meetingId && !done && (
        <Box style={{ textAlign: "center" }}>
        <Box mb={2}>
        <Button variant="contained" onClick={handleCancelClick}>
          Confirm
        </Button>
        </Box>
          <Typography variant="body2">If your booking also includes a link to a Teams meeting,<br />we just remove the meeting room from the booking without deleting the Teams meeting.</Typography>
          </Box>
      )}
      {cancelling && <CircularProgress />}
      {done && (
        <Box style={{ textAlign: "center" }}>
          <Typography variant="h5">Thanks.</Typography>
          <Typography variant="body2">This small act of kindness will really help a busy colleague ;o)<br />You can close this window now</Typography>
           
        </Box>
      )}
    </div>
  );
}

export default App;
